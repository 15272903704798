import React from 'react'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import {
  arrowDownIcon,
  arrowLeftIcon,
  arrowRightIcon,
  aiIcon,
  checkedIcon,
  clearIcon,
  errorIcon,
  hidePasswordIcon,
  loginIcon,
  logoutIcon,
  showPasswordIcon,
  notificationIcon,
  notificationFillIcon,
  plusIcon,
  flagIcon,
  fileIcon,
  folderIcon,
  testCaseIcon,
  testCheckIcon,
  testTaskIcon,
  priority_critical,
  priority_high,
  priority_low,
  priority_medium,
  priority_very_low,
  draftIcon,
  refinementIcon,
  searchIcon,
  backIcon,
  editIcon,
  clipIcon,
  taskIcon,
  knowledgeIcon,
  gitIcon,
  testerIcon,
  test_lead,
  project_manager,
  calendarIcon,
  clockIcon,
  historyIcon,
  reportsIcon,
  tagIcon,
  textFileIcon,
  videoIcon,
  runIcon,
  copyIcon,
  actionIcon,
  foldersIcon,
  importIcon,
  exportIcon,
  userIcon,
  supportIcon,
  downloadIcon,
  refreshIcon,
  etcIcon,
  deleteIcon,
  minusIcon,
  doubleArrowIcon,
  resendIcon,
  mailIcon,
  phoneIcon,
  keyIcon,
  attentionIcon,
  adminIcon,
  websiteIcon,
  locationIcon,
  commentIcon,
  reverseArrowIcon,
  integrationsIcon,
  arrowUpIcon,
  gitlabIcon,
  telegramIcon,
  jiraIcon,
  eyeIconClose,
  eyeIconOpen,
  broomIcon,
  bugIcon,
  jiraBug,
  sortASCicon,
  sortDESCicon,
  eqatorIcon,
  testitIcon,
  qaseIcon,
  resetIcon,
  zoomIn,
  zoomOut,
  arrowUp,
  arrowDown,
  exitIcon,
  filterIcon,
  commentPlus,
  dotsIcon,
  stepsIcon,
  settingsIcon,
  tileIcon,
  listIcon,
  starIcon,
  starFillIcon,
  attachIcon,
  downloadDownIcon,
  downloadArrowIcon,
  moveToIcon,
  attentionIcon2,
  promptIcon,
  filterIcon2,
  exclamationMarkIcon,
  failedIcon,
  runningIcon,
  descriptionIcon,
  questionIcon,
  notificationsMuteIcon
} from '../../images'
import style from './styles/icon.module.scss'

// TODO: Добавил в тип - добавь на страницу UI
export const iconType = {
  arrow_left: arrowLeftIcon,
  arrow_right: arrowRightIcon,
  arrow_down: arrowDownIcon,
  arrow_up: arrowUpIcon,
  ai_icon: aiIcon,
  checked: checkedIcon,
  clear: clearIcon,
  error: errorIcon,
  hide_password: hidePasswordIcon,
  login: loginIcon,
  logout: logoutIcon,
  show_password: showPasswordIcon,
  notification: notificationIcon,
  notificationFill: notificationFillIcon,
  notificationMute: notificationsMuteIcon,
  plus: plusIcon,
  folder: folderIcon,
  flag: flagIcon,
  file: fileIcon,
  test_case: testCaseIcon,
  test_check: testCheckIcon,
  test_task: testTaskIcon,
  draft: draftIcon,
  refinement: refinementIcon,
  priority_very_low,
  priority_low,
  priority_medium,
  priority_high,
  priority_critical,
  search: searchIcon,
  back: backIcon,
  edit: editIcon,
  clip: clipIcon,
  task: taskIcon,
  knowledge: knowledgeIcon,
  git: gitIcon,
  tester: testerIcon,
  test_lead,
  project_manager,
  calendar: calendarIcon,
  clock: clockIcon,
  history: historyIcon,
  reports: reportsIcon,
  tag: tagIcon,
  text_file: textFileIcon,
  video: videoIcon,
  run: runIcon,
  copy: copyIcon,
  action: actionIcon,
  folders: foldersIcon,
  gitlab_icon: gitlabIcon,
  jira_icon: jiraIcon,
  import: importIcon,
  export: exportIcon,
  user: userIcon,
  support: supportIcon,
  download: downloadIcon,
  refresh: refreshIcon,
  etc: etcIcon,
  delete: deleteIcon,
  minus: minusIcon,
  doubleArrow: doubleArrowIcon,
  resend: resendIcon,
  mail: mailIcon,
  phone: phoneIcon,
  key: keyIcon,
  attention: attentionIcon,
  attention2: attentionIcon2,
  admin: adminIcon,
  website: websiteIcon,
  location: locationIcon,
  comment: commentIcon,
  reverseArrow: reverseArrowIcon,
  integrations: integrationsIcon,
  gitlab: gitlabIcon,
  telegram: telegramIcon,
  asc: sortASCicon,
  desc: sortDESCicon,
  eye_open: eyeIconOpen,
  eye_close: eyeIconClose,
  broom: broomIcon,
  bug: bugIcon,
  jira_bug: jiraBug,
  eqatorIcon: eqatorIcon,
  testitIcon: testitIcon,
  qaseIcon: qaseIcon,
  resetIcon: resetIcon,
  zoomIn: zoomIn,
  zoomOut: zoomOut,
  upArrow: arrowUp,
  downArrow: arrowDown,
  exitIcon: exitIcon,
  filter: filterIcon,
  filter2: filterIcon2,
  settings: settingsIcon,
  commentPlus: commentPlus,
  dotsIcon: dotsIcon,
  steps: stepsIcon,
  tile: tileIcon,
  list: listIcon,
  star: starIcon,
  starFill: starFillIcon,
  attach: attachIcon,
  downloadDown: downloadDownIcon,
  downloadArrow: downloadArrowIcon,
  moveTo: moveToIcon,
  prompt: promptIcon,
  exclamationMark: exclamationMarkIcon,
  failedIcon: failedIcon,
  runningIcon: runningIcon,
  description: descriptionIcon,
  question: questionIcon
} as const

export type TIcon = keyof typeof iconType | ''

interface IconProps {
  src: TIcon
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'
  className?: string
  slot?: string
  onClick?: (e) => void
}

const Icon = ({
  src,
  size = 'medium',
  className = '',
  ...props
}: IconProps): React.ReactElement => {
  const iconClasses = classNames({
    [style.icon]: true,
    [style[`icon--${size}`]]: size,
    [className]: Boolean(className)
  })

  const iconSrc = iconType[src]

  return <GxIcon className={iconClasses} src={iconSrc} {...props} />
}

export default Icon
