import { GxMenuItem } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/item.module.scss'

interface IMenuItemProps {
  disabled?: boolean
  value?: string | number
  className?: string
  multiple?: boolean
  onClick?: () => void
}

const MenuItem = ({
  children,
  value = undefined,
  className = '',
  multiple = false,
  ...props
}: PropsWithChildren<IMenuItemProps>): React.ReactElement => {
  const typedValue = value !== undefined ? String(value) : undefined
  return (
    <GxMenuItem
      value={typedValue}
      className={classNames({
        [style.default]: true,
        [className]: Boolean(className)
      })}
      {...props}
    >
      {multiple
        ? (
          <span slot='prefix' className={style.check}>
            <Icon src='checked' size='small' className={style.check__icon} />
          </span>
          )
        : null}
      {children}
    </GxMenuItem>
  )
}

export default MenuItem
