import classNames from 'classnames'
import React from 'react'
import Icon from '../Icon/Icon'
import { IAccordionControlledProps } from './interface'
import style from './styles/accordion.module.scss'

const ControlledAccordion = ({
  head,
  children,
  className = '',
  isOpen,
  setIsOpen,
  headRight,
  overflowWhenOpened = false
}: IAccordionControlledProps): React.ReactElement => {
  const handleToggleOpened = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={className}>
      <button
        className={style.accordion__head}
        type='button'
        onClick={handleToggleOpened}
      >
        {head}

        {headRight !== null && headRight !== undefined
          ? (
            <div className={style.accordion__right}>
              {headRight}
              <Icon
                src='arrow_down'
                className={classNames({
                  [style.accordion__icon]: true,
                  [style['accordion__icon--opened']]: isOpen
                })}
              />
            </div>
            )
          : (
            <Icon
              src='arrow_down'
              className={classNames({
                [style.accordion__icon]: true,
                [style['accordion__icon--opened']]: isOpen
              })}
            />
            )}
      </button>

      <div
        className={classNames({
          [style.accordion__body]: true,
          [style['accordion__body--opened']]: isOpen,
          [style['accordion__body--overflow']]: overflowWhenOpened
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default ControlledAccordion
