import { languages, defaultLanguage } from '@/i18n'
import { IActivityDataset, IStatistics } from '@/interfaces/Runs'
import { ITimeSince } from '@/interfaces/Utils'
import {
  format,
  formatISO,
  isValid,
  parse,
  startOfDay,
  subDays
} from 'date-fns'
import { PasswordStrength, TFileType } from '@/interfaces/Types'
import { FILE_EXTENSIONS } from '@/const'
import DOMPurify from 'dompurify'

export const getLocaleByPathname = (pathname: string): string => {
  const language = pathname.split('/')[1]
  return languages.includes(language) ? language : defaultLanguage
}

export const debounce = (
  fn: (...params: any[]) => any,
  n: number,
  immed: boolean = false
): any => {
  let timer: number | undefined
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args)
    }
    if (timer !== undefined) {
      clearTimeout(timer)
    }
    // @ts-expect-error
    timer = setTimeout(() => fn.apply(this, args), n)
    return timer
  }
}

export const nope = (): void => {}

export function downloadBase64File (contentBase64, fileName): void {
  const linkSource = contentBase64
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)

  downloadLink.href = linkSource
  downloadLink.target = '_self'
  downloadLink.download = fileName
  downloadLink.click()
  downloadLink.remove()
}

export function togglePresent<T> (arr: T[], el: T): T[] {
  const idx = arr.indexOf(el)
  if (idx >= 0) {
    const newArr = [...arr]
    newArr.splice(idx, 1)
    return newArr
  } else {
    return [...arr, el]
  }
}

export const isString = (value): boolean =>
  typeof value === 'string' || value instanceof String

/**
 * преобразует вложенный объект в плоский объект, объединяя ключи в виде строки с точкой.
 */
export function flattenObject (ob): any {
  const toReturn = {}

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i) === false) {
      continue
    }

    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i])
      for (const x in flatObject) {
        if (Object.prototype.hasOwnProperty.call(flatObject, x) === false) {
          continue
        }

        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

export function flattenObjectKeys (obj): string[] {
  return Object.keys(flattenObject(obj))
}

export function formatDateForBackend (date: string): string {
  return format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
}

export function formatDateForFrontend (date: string): string {
  const d = parse(date, 'yyyy-MM-dd', new Date())
  if (!isValid(d)) {
    return ''
  }
  return format(d, 'dd/MM/yyyy')
}

export function resolve (path, obj, separator = '.'): any {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}

const arrayJoinToString = (list: string[]): string =>
  Array.isArray(list) ? list.join(' ') : list

/**
 *
 * @param params параметры формочки
 * @param error массив ошибок
 * @param deepKey !необязательный параметр! нужен для вложенности
 * @returns
 */
export function reactHookFormErrorFormater (
  params,
  error,
  deepKey = ''
): Array<{ [key: string]: string }> {
  const result: Array<{ [key: string]: string }> = []
  if (error.response.data === undefined) {
    result.push({
      key: 'non_field_errors',
      value: 'Server error'
    })
  }
  if (error.response !== undefined) {
    const { data } = error.response
    if (typeof data === 'object') {
      const keys = flattenObjectKeys(params)

      const errorsData = deepKey === '' ? data : data[deepKey]
      keys.forEach((key: never): void => {
        const currentError = resolve(key, errorsData)
        if (currentError !== undefined) {
          const errorString = Array.isArray(currentError)
            ? currentError.join(' ')
            : currentError
          result.push({ key: key, value: errorString })
        }
      })

      if (errorsData !== undefined) {
        if (errorsData.non_field_errors !== undefined) {
          const errors = errorsData.non_field_errors
          const errorString = arrayJoinToString(errors)
          result.push({ key: 'non_field_errors', value: errorString })
        }

        if (data.non_field_errors !== undefined) {
          const errors = data.non_field_errors
          const errorString = arrayJoinToString(errors)
          result.push({ key: 'non_field_errors', value: errorString })
        }

        if (data.non_field_error !== undefined) {
          const errors = data.non_field_error
          const errorString = arrayJoinToString(errors)
          result.push({ key: 'non_field_errors', value: errorString })
        }
      }
    }
  } else {
    result.push({ key: 'non_field_errors', value: 'errors.server' })
  }
  return result
}

/** Форматирует размер в байтах в килобайте, мегабайты и т.д.
 * @param bytes - размер файла
 * @param si True для использования метрических (SI) единиц (степени 1000).
 *           False для использования двоичного (IEC) (степени 1024).
 * @param dp - количество знаков после запятой
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return String(bytes) + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

export const randomString = (length: number = 10): string => {
  let rnd = ''
  while (rnd.length < length) rnd += Math.random().toString(36).substring(2)
  return rnd.substring(0, length)
}

export function checkImageInURL (url: string): boolean {
  if (url !== '' && typeof url === 'string') {
    return url.match(/\.(jpeg|jpg|gif|png|svg)$/) !== null
  }
  return false
}

export const calculatePercent = (
  value: number,
  count: number | undefined
): number => {
  if (count === undefined || count === 0) return 0

  return Number(((value / count) * 100).toFixed(2))
}

export const daysPeriodStart = (days): string => {
  return formatISO(startOfDay(subDays(new Date(), days - 1)), {
    representation: 'date'
  })
}

export const makeAbbr = (words: string): string => {
  if (words === '') return ''
  const aw = words
    .trim()
    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')
    .split(' ')
  if (aw.length < 2) {
    return words.toUpperCase().slice(0, 7)
  }
  return aw
    .map((word) => {
      if (word === '') return ''
      return word[0].toUpperCase()
    })
    .join('')
}

export function urlSlug (s, opt?): string {
  s = String(s)
  opt = Object(opt)

  const defaults = {
    delimiter: '-',
    limit: undefined,
    lowercase: true,
    replacements: {},
    transliterate: true
  }

  // Merge options
  for (const k in defaults) {
    // const hasProp = opt.hasOwnProperty(k) === false
    if (Object.prototype.hasOwnProperty.call(opt, k) === false) {
      opt[k] = defaults[k]
    }
  }

  const charMap = {
    // Russian
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Е: 'E',
    Ё: 'Yo',
    Ж: 'Zh',
    З: 'Z',
    И: 'I',
    Й: 'J',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ф: 'F',
    Х: 'H',
    Ц: 'C',
    Ч: 'Ch',
    Ш: 'Sh',
    Щ: 'Sh',
    Ъ: '',
    Ы: 'Y',
    Ь: '',
    Э: 'E',
    Ю: 'Yu',
    Я: 'Ya',
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'yo',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sh',
    ъ: '',
    ы: 'y',
    ь: '',
    э: 'e',
    ю: 'yu',
    я: 'ya'
  }

  // Make custom replacements
  for (const k in opt.replacements) {
    s = s.replace(RegExp(k, 'g'), opt.replacements[k])
  }

  // Transliterate characters to ASCII
  if (opt.transliterate === true) {
    for (const k in charMap) {
      s = s.replace(RegExp(k, 'g'), charMap[k])
    }
  }

  // Replace non-alphanumeric characters with our delimiter
  const alnum = /'[^a-z0-9]+', 'ig'/
  s = s.replace(alnum, opt.delimiter)

  // Remove duplicate delimiters
  s = s.replace(RegExp(`[${String(opt.delimiter)}]{2,}`, 'g'), opt.delimiter)

  // Truncate slug to max. characters
  s = s.substring(0, opt.limit)

  // Remove delimiter from ends
  s = s.replace(
    RegExp(`(^${String(opt.delimiter)}|${String(opt.delimiter)}$)`, 'g'),
    ''
  )

  return opt.lowercase === true ? s.toLowerCase() : s
}

export const downloadFile = (data: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', fileName)
  tempLink.click()
}

/**
 * Функция downloadFileRef загружает файл, на который ссылается ссылка React на элемент видео или
 * изображения с необязательным указанным именем.
 * @param ref - Параметр ref — это React RefObject, который может ссылаться либо на HTMLVideoElement,
 * либо на HTMLImageElement. Это позволяет вам получать доступ к элементам DOM, представляющим видео
 * или изображение, в компоненте React и манипулировать ими.
 * @param {string} [name] - Параметр name в функции downloadFileRef представляет собой строку,
 * представляющую имя загружаемого файла. Если имя не указано, по умолчанию используется
 * «downloadFile».
 */
export const downloadFileRef = (
  ref: React.RefObject<HTMLVideoElement | HTMLImageElement>,
  name?: string
): void => {
  if (ref.current === null) return
  const link = document.createElement('a')
  link.download = name !== undefined && name !== '' ? name : 'downloadFile'
  link.href = ref.current.src
  link.click()
  link.parentNode?.removeChild(link)
}

/** Производит скролл до верха страницы
 */
export const scrollTop = (): void => {
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto'
  })
}

/** Производит скролл до указанного блока
 * @param ref - реф на блок до которого должен происходить скролл
 */
export const scrollToBlock = (ref: React.RefObject<any>): void => {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}

/** Превращает строку с датой в объект типа { count: 4, period: "years" }
 * @param date - строка с датой
 * @returns '{ count: 4, period: "years" }'
 */
export const timeSince = (date: string): ITimeSince => {
  const seconds = Math.floor(
    (Number(new Date()) - Number(new Date(date))) / 1000
  )

  let interval = seconds / 31536000

  if (interval > 1) {
    return { count: Math.floor(interval), period: 'years' }
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return { count: Math.floor(interval), period: 'months' }
  }
  interval = seconds / 86400
  if (interval > 1) {
    return { count: Math.floor(interval), period: 'days' }
  }
  interval = seconds / 3600
  if (interval > 1) {
    return { count: Math.floor(interval), period: 'hours' }
  }
  interval = seconds / 60
  if (interval > 1) {
    return { count: Math.floor(interval), period: 'minutes' }
  }
  return { count: Math.floor(seconds), period: 'seconds' }
}

/** Превращает количество секунд в число часов
 * @param seconds - количество секунд
 * @returns '{ count: 4, period: "hours" }'
 */
export const timeLeft = (seconds: number): ITimeSince => {
  if (Math.floor(seconds / 60 / 60) > 0) {
    return { count: Math.floor(seconds / 60 / 60), period: 'hours' }
  }
  if (Math.floor(seconds / 60) % 60 > 0) {
    return {
      count: Math.floor(Math.floor(seconds / 60) % 60),
      period: 'minutes'
    }
  }
  return { count: Math.floor(seconds % 3600), period: 'seconds' }
}

/** Принимает датасет для графика, возвращает статистику по последнему дню  */
export const getLastDayStatistic = (
  datasets: IActivityDataset[]
): IStatistics => {
  const statistic: Partial<IStatistics> = {
    blocked: 0,
    count: 0,
    failed: 0,
    passed: 0,
    retest: 0,
    untested: 0
  }
  let count = 0

  datasets.forEach((el) => {
    const label = el.label.toLowerCase()
    const value = el.data[el.data.length - 1]
    count = count + (value ?? 0)

    statistic[label] = value ?? 0
  })

  statistic.count = count

  return statistic as IStatistics
}

/** Тримит строку и убирает двойные пробелы внутри */
export const getTrimedString = (text: string): string => {
  return text.trim().replace(/\s+/g, ' ')
}

/** Возвращает случайный элемент из любого массива или выбрасывает ошибку, если массив пустой */
export const getRandomElement = <T>(items: T[]): T => {
  if (items.length === 0) {
    throw new Error('Array is empty') // выбрасываем ошибку, если массив пустой
  }

  const random = Math.floor(Math.random() * items.length)
  return items[random]
}

/**
 *  Определяет уровень сложности пароля на основе следующих критериев:
 * - Длина пароля более или равна 8 символам
 * - Наличие строчных букв (a-z)
 * - Наличие заглавных букв (A-Z)
 * - Наличие цифр (\d)
 * - Наличие специальных символов
 *
 * @param password Пароль для оценки уровня сложности.
 * @returns Уровень сложности пароля: 'weak', 'medium' или 'strong'.
 */
export const checkPasswordStrength = (password: string): PasswordStrength => {
  if (
    // сильный обязательно включает в себя маленькую букву, большую букву, цифру, спец. символ, более 10 символов
    password.length > 10 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /\d/.test(password) &&
    /[!@#$%^&*(),.?":{}|<>]/.test(password)
  ) {
    return 'strong'
  } else if (
    // средний пароль - есть маленькие буквы, есть цифры, 1 спец. символ и/или 1 большая буква
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /\d/.test(password) &&
    (/[!@#$%^&*(),.?":{}|<>]/.test(password) || /[A-Z]/.test(password))
  ) {
    return 'medium'
  } else {
    // слабый пароль - все буквы маленькие, есть цифры, до 10 символов
    return 'weak'
  }
}

export const checkTypeFile = (name: string): TFileType => {
  const ext = name.split('.').pop()?.toLowerCase()

  if (ext === undefined || ext === '') {
    return ''
  }

  if (FILE_EXTENSIONS.VIDEO.includes(ext)) {
    return 'video'
  } else if (FILE_EXTENSIONS.IMAGE.includes(ext)) {
    return 'image'
  } else if (FILE_EXTENSIONS.DOCUMENT.includes(ext)) {
    return 'text'
  }

  return ''
}

/**
 * Функция checkTypeFileWithProps проверяет, соответствует ли тип файла какому-либо из указанных типов
 * в массиве.
 * @param {string} name - Параметр name - это строка, представляющая имя файла, включая его
 * расширение.
 * @param {string[]} typeArray - Параметр typeArray представляет собой массив строк, содержащий
 * разрешенные расширения файлов для проверки.
 * @returns Функция checkTypeFileWithProps возвращает логическое значение, которое указывает, включено
 * ли расширение файла данного параметра name в параметр typeArray.
 */
export const checkTypeFileWithProps = (
  name: string,
  typeArray: string[]
): boolean => {
  const ext = name.split('.').pop()?.toLowerCase()

  if (ext === undefined || ext === '') {
    return false
  }

  return typeArray.includes(ext)
}

/**
 * Находит ссылки в тексте и оборачивает их в тег <a> с прокинутыми параметрами
 */
export const urlifyString = (
  text: string,
  className?: string,
  target?: React.HTMLAttributeAnchorTarget
): string => {
  const urlRegex =
    // eslint-disable-next-line
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return `<a href="${url}" ${className !== undefined ? `class="${className}"` : ''} ${target !== undefined ? `target="${target}" rel="noopener noreferrer"` : ''}>${url}</a>`
  })
}

export const hasStringValue = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value?.trim() === '') {
    return false
  }

  return true
}

/* Эта строка кода создает псевдоним типа objectKeys для метода Object.keys. Он указывает,
что objectKeys — это функция, которая принимает объект типа T и возвращает массив
ключей типа string из этого объекта. */
export const objectKeys = Object.keys as <T>(
  o: T
) => Array<Extract<keyof T, string>>

export const parseDate = (
  date: string | undefined,
  format: string = 'dd/MM/yyyy'
): Date | undefined => {
  if (date === undefined) {
    return undefined
  }
  return parse(date, format, new Date())
}

/**
 *   Проверяет, является ли текст пустым, учитывая, что в тексте могут быть HTML-теги
 *   (кроме тега img, который пропускается). Функция использует DOMPurify для очистки
 *   текста от HTML-тегов, кроме тега img, и пробелов, а затем сравнивает очищенный
 *   текст с пустой строкой. Также удаляются неразрывные пробелы (&nbsp;).
 *
 *   @param {string} text
 *   @returns {boolean}
 */
export const isRichTextEmpty = (text: string): boolean => {
  const clean = DOMPurify.sanitize(text, {
    ALLOWED_ATTR: [],
    ALLOWED_TAGS: ['img']
  })
  return clean.replace(/&nbsp;/g, '').trim() === ''
}

/**
 *   Функция getFileIdFromURL возвращает уникальный идентификатор файла, который
 *   является именем файла без расширения, из URL-адреса файла.
 *   @param {string} fileUrl
 *   @returns {string | undefined}
 */
export const getFileIdFromURL = (fileUrl: string): string | undefined => {
  return fileUrl.split('/').pop()?.split('.')[0]
}
