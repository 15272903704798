import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button/Button'
import Icon, { TIcon } from '../Icon/Icon'
import style from './styles/modal.module.scss'

interface IProps {
  onCancel: () => void
  onAction: () => void
  children: React.ReactNode
  disableActionButton?: boolean
  confirmText?: string
  actionButtonIcon?: TIcon
}

const ModalAction = ({
  onCancel,
  onAction,
  children,
  disableActionButton = false,
  confirmText,
  actionButtonIcon = 'checked'
}: IProps): React.ReactElement => {
  const confirmButtonText = confirmText ?? (
    <FormattedMessage id='common.ok' defaultMessage='Ok' />
  )

  return (
    <div className={style.delete}>
      {children}

      <div className={style.delete__footer} slot='footer'>
        <Button theme='light' onClick={onCancel}>
          <Icon src='clear' slot='icon-left' />
          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
        </Button>

        <Button onClick={onAction} disabled={disableActionButton}>
          <Icon src={actionButtonIcon} slot='icon-left' />
          {confirmButtonText}
        </Button>
      </div>
    </div>
  )
}

export default ModalAction
