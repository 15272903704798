import Icon from '@/components/Icon/Icon'
import { TCaseStatus, TCaseStatusVariants } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import style from './styles/status.module.scss'

interface IProps {
  variant?: TCaseStatusVariants
  status: TCaseStatus
  className?: string
  size?: 'medium' | 'small'
}

const STATUS_ICON = {
  draft: 'draft',
  approved: 'checked',
  refinement: 'refinement'
}

const CaseStatus = ({
  variant = 'default',
  status,
  className = '',
  size = 'medium'
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const stat = status !== undefined ? status : ''

  const defaultMessage = stat.charAt(0).toUpperCase() + stat.slice(1)

  const statusText = intl.formatMessage({
    id: `status.case.${status}`,
    defaultMessage
  })

  return (
    <div
      className={classNames({
        [style.status]: true,
        [style.case]: true,
        [style[`case--${status}`]]: true,
        [style[`case--${size}`]]: size !== 'medium',
        [className]: Boolean(className)
      })}
    >
      <Icon
        className={classNames({
          [style.case__icon]: true,
          [style[`case__icon--${status}`]]: true
        })}
        src={STATUS_ICON[stat]}
      />
      {variant === 'default'
        ? (
          <span className={style.case__text}>{statusText}</span>
          )
        : null}
    </div>
  )
}

export default CaseStatus
