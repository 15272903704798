import React from 'react'
import classNames from 'classnames'
import DOMPurify from 'isomorphic-dompurify'
import style from './styles/html_content.module.scss'
import { ALLOWED_ATTR, ALLOWED_TAGS } from '@/const'

interface Content {
  content: string
  className: string
}

const HtmlContent = ({
  content = '',
  className = ''
}): React.ReactElement<Content> => {
  const clean = DOMPurify.sanitize(content, { ALLOWED_ATTR, ALLOWED_TAGS })

  const classes = classNames({
    [style.html_content]: true,
    [className]: Boolean(className)
  })

  return (
    <div className={classes} dangerouslySetInnerHTML={{ __html: clean }} />
  )
}

export default HtmlContent
